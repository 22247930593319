import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { PathLike } from 'fs';

class Api {
    private api: AxiosInstance;
    private TOKEN_KEY = 'token';

    private config = {
        returnRejectedPromiseOnError: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            common: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Pragma: 'no-cache',
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        },
        paramsSerializer: (params: PathLike): string => qs.stringify(params, { indices: false })
    };

    public constructor() {
        this.api = axios.create(this.config);

        this.api.interceptors.request.use((request: AxiosRequestConfig) => {
            const token = sessionStorage.getItem(this.TOKEN_KEY);
            if (token) {
                request.headers.Authorization = `Bearer ${token}`;
            }

            return request;
        });

        this.api.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            error => {
                if (error.response.config.url !== '/auth/login' && error.response.status === 401) {
                    window.location.pathname = '/login';
                }

                return Promise.reject(error);
            }
        );
    }

    public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.get(url, config);
    }

    public post<T, R = AxiosResponse<T>>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<R> {
        return this.api.post(url, data, config);
    }

    public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.delete(url, config);
    }
}

const api = new Api();
export default api;
