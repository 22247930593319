import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from '../api';

import { IProduct, IVariant } from '../../../common/product';

import { Layout } from './_layout';

type Form = {
    code: string;
    name: string;
    kind: number;
    sideLength: number;
    bridgeWidth: number;
    lensDiameter: number;
};

export const Manage: React.FC = () => {
    const history = useHistory();
    const { handleSubmit, register, errors, setValue } = useForm<Form>();

    const { pid } = useParams<{ pid: string }>();

    const [variants, setVariants] = useState<IVariant[]>();

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const result = await api.get<IProduct>(`/product/${pid}`);

                setValue('code', result.data.code);
                setValue('name', result.data.name);
                setValue('kind', result.data.kind);
                setValue('sideLength', result.data.sideLength);
                setValue('bridgeWidth', result.data.bridgeWidth);
                setValue('lensDiameter', result.data.lensDiameter);

                setVariants(result.data.variants);
            } catch (e) {
                toast.error(e.message);
            }
        };

        if (pid) {
            fetchCategory();
        }
    }, [pid, setValue]);

    const onSubmitProduct = async (values: Form) => {
        try {
            const result = await api.post<{ id: string }>('/product/manage', { id: pid, product: values });

            toast.success('Categoria salvata con successo');

            if (!pid) {
                history.replace(`/manage/${result.data.id}`);
            }
        } catch (e) {
            toast.error('Si è verificato un errore durante il salvataggio');
        }
    };

    /* const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
         if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const data = new FormData();
            data.append('id', cid as string);
            data.append('image', file, file.name);

            try {
                const result = await api.post<string>('/category/image', data);
                setImage(result.data);

                toast.success('Immagine caricata con successo');
            } catch (e) {
                toast.error(e.message);
            }
        }
    };

    const deleteImage = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questa immagine?')) {
            try {
                await api.delete(`/category/image/${cid}`);

                setImage(undefined);

                toast.success('Immagine eliminata con successo');
            } catch (e) {
                toast.error(e.message);
            }
        }
    }; */

    return (
        <Layout>
            <h1>Gestisci prodotto</h1>

            <form className="mt-8" onSubmit={handleSubmit(onSubmitProduct)}>
                <div className="grid grid-cols-4 gap-x-4 gap-y-4">
                    <div>
                        <label className="block font-bold mb-1" htmlFor="code">
                            Codice
                        </label>
                        <input
                            name="code"
                            placeholder="Codice"
                            type="text"
                            className="input focus:outline-none focus:shadow-outline"
                            ref={register({ required: true })}
                        />
                        {errors.code && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il codice è obbligatorio</div>}
                    </div>
                    <div className="col-span-3">
                        <label className="block font-bold mb-1" htmlFor="name">
                            Nome prodotto
                        </label>
                        <input
                            name="name"
                            placeholder="Nome prodotto"
                            type="text"
                            className="input focus:outline-none focus:shadow-outline"
                            ref={register({ required: true })}
                        />
                        {errors.name && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome del prodotto è obbligatorio</div>}
                    </div>
                    <div>
                        <label className="block font-bold mb-1" htmlFor="kind">
                            Tipologia
                        </label>
                        <div className="inline-block relative w-full">
                            <select
                                className="block appearance-none w-full bg-white border border-gray-400 p-4 pr-8 rounded"
                                name="kind"
                                ref={register({ required: true })}
                            >
                                <option value="0">Uomo</option>
                                <option value="1">Donna</option>
                                <option value="2">Unisex</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className="block font-bold mb-1" htmlFor="sideLength">
                            Asta
                        </label>
                        <input
                            name="sideLength"
                            placeholder="Asta"
                            type="text"
                            className="input focus:outline-none focus:shadow-outline"
                            ref={register({ required: true, pattern: /\d+/ })}
                        />
                        {errors.sideLength && (
                            <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La lunghezza asta è un numero ed è obbligatoria</div>
                        )}
                    </div>
                    <div>
                        <label className="block font-bold mb-1" htmlFor="bridgeWidth">
                            Naso
                        </label>
                        <input
                            name="bridgeWidth"
                            placeholder="Naso"
                            type="text"
                            className="input focus:outline-none focus:shadow-outline"
                            ref={register({ required: true, pattern: /\d+/ })}
                        />
                        {errors.bridgeWidth && (
                            <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Lo spazio naso è un numero ed è obbligatorio</div>
                        )}
                    </div>
                    <div>
                        <label className="block font-bold mb-1" htmlFor="lensDiameter">
                            Diametro lente
                        </label>
                        <input
                            name="lensDiameter"
                            placeholder="Diametro lente"
                            type="text"
                            className="input focus:outline-none focus:shadow-outline"
                            ref={register({ required: true, pattern: /\d+/ })}
                        />
                        {errors.lensDiameter && (
                            <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il diametro lente è un numero ed è obbligatorio</div>
                        )}
                    </div>
                </div>

                <button type="submit" className="button mt-8 w-full">
                    SALVA
                </button>
            </form>

            {pid && (
                <>
                    <h2 className="mt-8">Varianti</h2>
                    {variants && variants.length > 0 && (
                        <div className="mt-4 flex bg">
                            {variants.map(v => (
                                <div key={v.id} className="w-64 flex flex-col items-center mr-2 pt-2 bg-gray-200">
                                    <b>Cod.: {v.code}</b>
                                    <img src={v.image.url} className="mt-4" alt="" />
                                    <div className="mt-4">
                                        <input type="checkbox" checked={v.isPrincipal} /> Principale
                                    </div>
                                    <button className="mt-4 button w-full bg-red-600">Elimina</button>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </Layout>
    );
};
