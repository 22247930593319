import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useAuth } from '../providers/auth';

import logo from '../assets/logo.png';

type LoginForm = {
    email: string;
    password: string;
};

export const Login: React.FC = () => {
    const { login } = useAuth();
    const history = useHistory();
    const { handleSubmit, register, errors, formState } = useForm<LoginForm>({ mode: 'onChange' });

    const onSubmit = async (values: LoginForm) => {
        try {
            if (login) {
                await login(values.email, values.password);
                history.push('/');
            }
        } catch (e) {
            toast.error('Si è verificato un errore durante il login');
        }
    };

    return (
        <div className="h-screen flex items-center justify-center">
            <div className="w-1/3">
                <div className="p-4 flex justify-center">
                    <img src={logo} alt="Ottica VOIR Login" title="Ottica VOIR Login" />
                </div>
                <div className="p-4 bg-white rounded-lg">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            name="email"
                            placeholder="Email"
                            type="email"
                            className="input focus:outline-none focus:shadow-outline"
                            ref={register({
                                required: "L'email è obbligatoria",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "L'indirizzo email inserito non è valido"
                                }
                            })}
                        />
                        {errors.email && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">{errors.email.message}</div>}

                        <input
                            name="password"
                            placeholder="Password"
                            type="password"
                            className="mt-4 input focus:outline-none focus:shadow-outline"
                            ref={register({ required: true })}
                        />
                        {errors.password && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La password &egrave; obbligatoria</div>}

                        <button type="submit" className="button mt-8 w-full" disabled={!formState.isDirty || (formState.isDirty && !formState.isValid)}>
                            LOGIN
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
