import { ReactElement } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { PrivateRoute } from './PrivateRoute';

import { Login } from './views/login';
import { Home } from './views/home';
import { Products } from './views/products';
import { Manage } from './views/manage';

import 'react-toastify/dist/ReactToastify.css';
import './styles/index.generated.css';

export default function App(): ReactElement {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <PrivateRoute exact path="/" component={Home} />
                    <PrivateRoute exact path="/products" component={Products} />
                    <PrivateRoute exact path="/manage/:pid?" component={Manage} />
                </Switch>
            </Router>

            <ToastContainer
                key="ToastContainer"
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
            />
        </>
    );
}
