import { Layout } from './_layout';

export const Home: React.FC = () => {
    return (
        <Layout>
            <h1>Area amministrativa</h1>
            In questa sezione è possibile gestire tutti gli aspetti amministrativi del sito Ottica VOIR.
        </Layout>
    );
};
