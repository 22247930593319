import { ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../providers/auth';

import logo from '../assets/logo.png';

type LayoutProps = {
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { logout } = useAuth();
    const history = useHistory();

    const doLogout = async (): Promise<void> => {
        if (logout) {
            logout();
            history.push('/login');
        }
    };

    return (
        <>
            <nav className="flex items-center justify-between flex-wrap py-4 px-32 uppercase bg-gray-200">
                <div className="flex items-center flex-shrink-0 mr-6">
                    <img src={logo} className="h-16 mr-4" alt="Ottica VOIR" title="Ottica VOIR" />

                    <Link to="/" className="font-bold tracking-tight">
                        Ottica VOIR Area Riservata
                    </Link>
                </div>
                <div className="flex-grow flex items-center w-auto">
                    <div className="flex-grow">
                        <Link to="/products" className="inline-block mr-4">
                            Prodotti
                        </Link>
                        <Link to="/manage" className="inline-block">
                            Aggiungi prodotto
                        </Link>
                    </div>
                    <div>
                        <button className="inline-block px-6 py-4 font-bold leading-none rounded bg-white text-voir" onClick={doLogout}>
                            LOGOUT
                        </button>
                    </div>
                </div>
            </nav>

            <main className="py-4 px-32">{props.children}</main>
        </>
    );
};
