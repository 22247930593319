import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { AuthProvider } from './providers/auth';
import App from './App';

ReactDOM.render(
    <StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </StrictMode>,
    document.getElementById('root')
);
