import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../api';

import { Layout } from './_layout';

type Product = {
    id: string;
    code: string;
    name: string;
    kind: number;
    lensDiameter: number;
    bridgeWidth: number;
    sideLength: number;
};

export const Products: React.FC = () => {
    const [products, setProducts] = useState<Product[]>();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const result = await api.get<Product[]>('/products');
                setProducts(result.data);
            } catch (e) {
                toast.error(e.message);
            }
        };

        fetchProducts();
    }, []);

    const deleteProduct = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo prodotto?')) {
            try {
                await api.delete(`/product/${id}`);

                const productIndex = products?.findIndex(s => s.id === id);
                if (products && productIndex && productIndex > -1) {
                    const tmp = [...products];
                    tmp.splice(productIndex, 1);
                    setProducts(tmp);
                }

                toast.success('Prodotto eliminato con successo');
            } catch (e) {
                toast.error(e.message);
            }
        }
    };

    return (
        <Layout>
            <h1>Prodotti</h1>
            {products && products.length > 0 && (
                <div className="mt-4 bg-white p-4 rounded">
                    <table className="border-collapse w-full">
                        <thead>
                            <tr>
                                <th className="w-12 p-3 text-left">#</th>
                                <th className="w-32 p-3 text-left">Codice</th>
                                <th className="w-auto p-3 text-left">Nome</th>
                                <th className="w-auto p-3 text-left">Tipologia</th>
                                <th className="w-auto p-3 text-left">Dimensioni</th>
                                <th className="w-64 p-3">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((p, i) => (
                                <tr key={i} className="table-row">
                                    <td className="border border-b px-3 py-1">{i + 1}</td>
                                    <td className="border border-b px-3 py-1">{p.code}</td>
                                    <td className="border border-b px-3 py-1">{p.name}</td>
                                    <td className="border border-b px-3 py-1">{p.kind === 0 ? 'Uomo' : p.kind === 1 ? 'Donna' : 'Unisex'}</td>
                                    <td className="border border-b px-3 py-1">
                                        {p.lensDiameter} x {p.bridgeWidth} x {p.sideLength}
                                    </td>
                                    <td className="border border-b px-3 py-1">
                                        <div className="flex">
                                            <Link to={`/manage/${p.id}`} className="button mr-2">
                                                Modifica
                                            </Link>
                                            <button className="button bg-red-600" onClick={() => deleteProduct(p.id)}>
                                                Elimina
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </Layout>
    );
};
