import { ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAuth } from './providers/auth';

export const PrivateRoute: React.FC<RouteProps> = (props: RouteProps): ReactElement => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) {
        return (
            <div className="h-full flex justify-center items-center text-pcons-green">
                <h3>Caricamento...</h3>
            </div>
        );
    }

    if (isAuthenticated) {
        return <Route {...props} />;
    } else {
        // eslint-disable-next-line react/prop-types
        const renderComponent = (): ReactElement => <Redirect to={{ pathname: '/login', state: { returnUrl: props.location?.pathname } }} />;
        return <Route {...props} component={renderComponent} render={undefined} />;
    }
};
